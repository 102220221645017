import { UrlMatchResult, UrlSegment } from "@angular/router";

export function retirementAnalyzerOldMatcher(url: UrlSegment[]): UrlMatchResult|null {
  //console.log("retirementAnalyzerOldMatcher >",url,url[0])  
   if (url[0] && url[0].path.includes('montecarlo')){
     const path = url[3].path;
     var m = path.match(/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/);
     if(m!=null){   
      //console.log("m[0]",m[0])  
       //window.location.href = window.location.origin+"/retirement-analyzer?edit="+m[0]; //couldn't get the query parameter to pass. named vs positional parameters? dunno      
       return {
         consumed: url,
         posParams: {        
           edit: new UrlSegment(m[0],{})
         },
       }    
     }  
   }
   return null;
 }