import { Component, Inject } from '@angular/core';
//import { BrowserModule } from '@angular/platform-browser';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { Data2Service } from './services/data2/data2.service';
import { LocalCacheService, LocalStorageService, TimeService } from '@ifa/ngx-data2-service';
import { Globals } from './globals';
import { SafePipe } from './pipes/safe.pipe';
import { RouterOutlet } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone:true,
  imports: [
    //CommonModule,
    //BrowserModule,
    //BrowserAnimationsModule,
    //NoopAnimationsModule,
    //AppRoutingModule,
    //FormsModule,
    //ReactiveFormsModule,
    RouterOutlet,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //     //enabled: environment.production,
    //     enabled: environment.production,
    //     // Register the ServiceWorker as soon as the app is stable
    //     // or after 30 seconds (whichever comes first).
    //     registrationStrategy: 'registerWhenStable:30000'
    // })       
  ], 
  providers: [
    //Data2Service,
    //LocalCacheService,
    //LocalStorageService,
    //TimeService,
    //// {provide: APP_BASE_HREF, useValue: '/'}, //No need for <base href="/"> which messes with SVG urls
    //Globals,
    //SafePipe, 
    //provideClientHydration(),
    //provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
  ]
})
export class AppComponent {
  //constructor(@Inject(DOCUMENT) private document: Document){}

}
 
