import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  template: `<!-- Intentionally left blank for redirection -->`,
})
export class MonteCarloRedirectComponent {  
  constructor(private router: Router, private route: ActivatedRoute) {}  
  
  ngOnInit() {  
    // Optionally, handle the logic here if you need to extract parameters or perform other checks  
    this.route.paramMap.subscribe(params => {  
      const edit = params.get('edit'); // Assuming 'edit' is a parameter you might need  
      if (edit) {  
        this.router.navigate(['/retirement-analyzer'], { queryParams: { edit } });  
      } else {  
        this.router.navigate(['/retirement-analyzer']);  
      }  
    });  
  }  
}  
 