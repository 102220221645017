import { Routes } from '@angular/router';
import { MasterGuard } from './guards/master.guard';
import { retirementAnalyzerOldMatcher } from './pages/route-matcher';
import { MonteCarloRedirectComponent } from './pages/retirement-analyzer/monte-carlo-redirect/monte-carlo-redirect.component';

export const routes: Routes = [
   
     { 
       path:"required-consent-stand-alone",
       loadChildren:() => import('./pages/required-consent-stand-alone/required-consent-stand-alone.module').then(m => m.RequiredConsentStandAloneModule)
     },
    { 
      path:"survey",
      loadChildren:() => import('./pages/survey/survey.module').then(m => m.SurveyModule), canActivate:[MasterGuard]
    },
    { 
      path:   'signin-oidc',  
      loadChildren: () => import('./pages/signin-oidc/signin-oidc.module').then(m => m.SigninOidcModule)
    },
    { 
      path:   'signout-oidc',  
      loadChildren: () => import('./pages/signout-oidc/signout-oidc.module').then(m => m.SignoutOidcModule)
    },
    {
      path:"",
       loadChildren:() => import('./pages/pages.module').then(m => m.PagesModule)    
    },
    { matcher:retirementAnalyzerOldMatcher, component: MonteCarloRedirectComponent }, //Matcher needs to use a component. component redirects
    {
      path:'',
      loadChildren:() => import('./pages/pages.module').then(m => m.PagesModule)    
    }
    // {
    //   path:'**',
    //   redirectTo:'404'
    // }
    // {
    //   path:'**',
    //   redirectTo:'404'
    // }
];
